<template>
  <v-container fluid>
    <v-card>
      <v-toolbar
        color="secondary"
        flat
      >
        <v-toolbar-title>Call Management</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <!-- start of filters -->
        <v-row
          dense
          align-content="space-around"
        >
          <v-col cols="6">
            <v-card-text class="pa-0 font-weight-bold">Filters</v-card-text>
          </v-col>
          <v-col cols="6">
            <v-card-text
              class="pa-0 font-weight-bold"
              left
            >Date Range</v-card-text>
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              dense
              outlined
              v-model="calls.SlpCode"
              :items="salesEmployees"
              item-text="SlpName"
              item-value="id"
              label="Sales Rep"
            ></v-autocomplete>
          </v-col>
          <v-col cols="3">
            <DatePicker
              @date="fromDate"
              :myDate="calls.fromDate"
              :title="`Date`"
            ></DatePicker>
          </v-col>
          <v-col cols="3">
            <DatePicker
              @date="toDate"
              :myDate="calls.toDate"
              :title="`Date`"
            ></DatePicker>
          </v-col>
          <v-col cols="3">
            <v-btn
              color="accent"
              @click="filterCalls"
            >Filter</v-btn>
          </v-col>
        </v-row>
        <!-- end of filters -->

        <!-- start of datatable -->
        <v-row>
          <v-col cols="6">
            <v-card-text class="pa-0 font-weight-bold">Call completion - Overview</v-card-text>
            <v-card-text class="pa-0">An overview report of call completion</v-card-text>
          </v-col>
          <v-col
            cols="3"
            offset-md="3"
          >
            <v-tooltip
              bottom
              color="grey lighten-5"
            >
              <!-- <template v-slot:activator="{ on, attrs }">
                <ExcelExport
                  :data="calls"
                  :fields="reportHeaders"
                  worksheet="Calls completion - by Sales Rep"
                  name="Calls completion - by Sales Rep.xls"
                >
                  <v-icon v-bind="attrs" v-on="on" color="green">mdi-file-excel-outline</v-icon>
                </ExcelExport>
              </template> -->
              <span class="black--text">Export to excel</span>
            </v-tooltip>
            <v-tooltip
              bottom
              color="grey lighten-5"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  @click="generatePDF()"
                  color="red"
                >mdi-file-pdf-outline</v-icon>
              </template>
              <span class="black--text">Export to PDF</span>
            </v-tooltip>
          </v-col>
        </v-row>

        <v-row class="pt-1">
          <v-col cols="12">
            <v-text-field
              v-model="search"
              label="Search"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="calls"
            >
              <!-- Status template -->
              <template v-slot:item.Status="props">
                <span v-if="props.item.Status == 'D'">Due</span>
                <span v-if="props.item.Status == 'C'">Closed</span>
                <span v-if="props.item.Status == 'A'">Abandoned</span>
              </template>
              <!-- end -->

              <!-- Approval  Status template -->
              <template v-slot:item.AprovalStatus="props">
                <span v-if="props.item.AprovalStatus == 'P'">Pending</span>
                <span v-if="props.item.AprovalStatus == 'A'">Approved</span>
              </template>
              <!-- end -->

              <!-- outlet template -->
              <template v-slot:item.outlet="props">
                <v-btn
                  color="blue lighten-1"
                  text
                  small
                  :to="`/outlet/${props.item.outlet.id}`"
                >{{props.item.outlet.CardName}}</v-btn>
              </template>
              <!-- end -->

              <!-- sales employee template -->
              <template v-slot:item.employees="props">
                <v-btn
                  color="blue lighten-1"
                  text
                  small
                  :to="`/sales/employee/${props.item.employees.id}`"
                >{{props.item.employees.SlpName}}</v-btn>
              </template>
              <!-- end -->

              <!-- Opened time template -->
              <template v-slot:item.OpenedTime="props">
                <span v-if="props.item.OpenedTime == null">Yet</span>
                <span v-else>{{props.item.OpenedTime}}</span>
              </template>
              <!-- end -->

              <!-- Close time template -->
              <template v-slot:item.CloseTime="props">
                <span v-if="props.item.CloseTime == null">Yet</span>
                <span v-else>{{props.item.CloseTime}}</span>
              </template>
              <!-- end -->

              <!-- Close time template -->
              <template v-slot:item.Repeat="props">
                <span v-if="props.item.Repeat == 'N'">Never</span>
                <span v-if="props.item.Repeat == 'D'">Daily</span>
                <span v-if="props.item.Repeat == 'W'">Weekly</span>
                <span v-if="props.item.Repeat == 'F'">Fortnightly</span>
                <span v-if="props.item.Repeat == 'M'">Monthly</span>
                <span v-if="props.item.Repeat == 'A'">Annually</span>
              </template>
              <!-- end -->
            </v-data-table>
          </v-col>
        </v-row>
        <!-- end of data table -->

        <!-- start of territory dialog -->
        <v-dialog
          v-model="dialog"
          width="650px"
        >
          <v-card>
            <v-toolbar
              color="accent"
              dark
              dense
            >
              <v-toolbar-title>Select Territory</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn
                icon
                @click.native="dialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <!-- territories -->
                <v-col cols="12">
                  <v-treeview
                    color="accent"
                    :activatable="trueValue"
                    :hoverable="trueValue"
                    :selection-type="selectionType"
                    open-all
                    return-object
                    item-text="descript"
                    item-key="id"
                    :items="territories"
                    item-children="children_recursive"
                    :search="search"
                  >
                    <template v-slot:label="{ item }">
                      <div @click="selectItem(item)">
                        <span style="font-weight: bold">{{item.descript}}</span>
                      </div>
                    </template>
                  </v-treeview>
                </v-col>
                <!-- end -->
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- end of territory dialog -->
      </v-card-text>
    </v-card>

    <snackbar refs="snackbar"></snackbar>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import jsPDF from "jspdf";
import "jspdf-autotable";
export default {
  computed: {
    ...mapGetters("territory", ["territories"]),
    ...mapGetters("salesEmployee", ["salesEmployees"]),
  },
  data() {
    return {
      loading: false,
      selectionType: "leaf",
      search: null,
      employeesFilterValue: null,
      calls: [],
      bpMasterData: [],
      trueValue: true,
      territory: {},
      dialog: false,
      modal2: false,
      time: null,
      reportHeaders: {
        "Sales rep": "employees.SlpName",
        Role: "CardName",
        Planned: "territory.descript",
        Completed: "territory.parent.descript",
        "Achieved %": "SlpCode",
      },
      headers: [
        { text: "Sales rep", value: "employees" },
        { text: "Role", value: "CallDate" },
        { text: "Planned", value: "CallTime" },
        { text: "Completed", value: "CallEndTime" },
        {
          text: "Achieved %",
          value: "employees",
        },
      ],
    };
  },
  methods: {
    getCalls() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/callsreport`)
        .then((res) => {
          self.calls = res;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getBpMaster() {
      const self = this;
      self.loading = true;
      this.$store
        .dispatch("get", `/bp_masterdata`)
        .then((res) => {
          self.bpMasterData = res;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    openRegionModal() {
      this.dialog = true;
    },
    selectItem(data) {
      this.territory = data;
      this.dialog = false;
    },
    fromDate(date) {
      this.calls.fromDate = date;
    },
    toDate(date) {
      this.calls.toDate = date;
    },
    filterCalls() {
      const data = {
        SlpCode: this.calls.SlpCode,
        fromDate: this.calls.fromDate,
        toDate: this.calls.toDate,
      };
      let url = `/searchCalls`;
      const self = this;
      self.loading = true;
      this.$store
        .dispatch("post", { url, data })
        .then((res) => {
          self.calls = res;
          self.loader = false;
        })
        .catch((err) => {
          console.log(err, "err");
          // this.$refs.snackbar.show(err, "red");
        });
    },
    employeesFilter(item) {
      // If this filter has no value we just skip the entire filter.
      if (!this.SlpCode) {
        return true;
      }
      // Check if the current loop value (The calories value)
      // equals to the selected value at the <v-select>.
      return item === this.SlpCode;
    },
    generatePDF() {
      let pdfName = "Calls completion - by Sales Rep";
      const doc = new jsPDF();
      let keys = Object.keys(this.reportHeaders);
      // let obj = this.headers;
      let data = this.calls;

      data.forEach((element) => {
        // var temp = [element.index, element.id];
        return element;
        // console.log(element, "value");
      });

      doc.autoTable({ head: [keys], body: [data] });
      // console.log(data, "table");
      doc.save(pdfName + ".pdf");
    },
  },
  created() {
    this.getCalls();
    this.getBpMaster();
  },
};
</script>

<style scoped>
.row {
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 0;
  padding-bottom: 0;
}
</style>